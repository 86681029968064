const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      
    ],
    
  },
  {
    heading: "studentsModule",
    route: "/apps",
    pages: [
      {
        sectionTitle: "studentsModule",
        heading: "students.add",
        route: "/students/add",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person-plus",     
        sub: [
          {
            heading: "settings",
            route: "/students",
          },
         
        ],   
      },
      {
        heading: "students.list",
        route: "/students",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person-lines-fill",
      },
    ],
  },
  {
    heading: "settings",
    route: "/apps",
    pages: [
      {
        heading: "accountSettings",
        route: "/students",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "companySettings",
        route: "/company/settings",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
      },
      {
        sectionTitle: "systemSettings",
        route: "/system",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-gear",
        sub: [
          {
            heading: "settings",
            route: "/students",
          },
         
        ],
      },
     
     
     
    ],
  },
];

export default DocMenuConfig;
